import { REACT_APP_HOST_URL } from "envs";
import { useState } from "react";

interface IImage {
  fileName?: string;
  url?: string;
  className?: string;
  height?: string;
  width?: string;
  props?: any;
  initials?: string
  initialsClassName?: string
  useFileNameOnFail?: boolean
}

export const Image = ({
  fileName,
  url: imageUrl,
  className,
  height,
  width,
  props,
  initials,
  initialsClassName,
  useFileNameOnFail
}: IImage) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const customStyle = {
    width: width + "px",
    height: height + "px",
    "border-radius": "50%",
    display: "inline-flex",
    "justify-content": "center",
    "align-items": "center"
  }

  let url =
    imageUrl ??
    `${
      REACT_APP_HOST_URL ? REACT_APP_HOST_URL : window.location.origin
    }/media/${fileName}`;

  if (useFileNameOnFail && imageError && fileName) {
    url = `${
      REACT_APP_HOST_URL ? REACT_APP_HOST_URL : window.location.origin
    }/media/${fileName}`
  }

  return (
    <>
      {imageError && !useFileNameOnFail ? (
        <div style={customStyle} className={initialsClassName ?? ""}>
          {initials?.charAt(0).toUpperCase() ?? ""}
        </div>
      ) : (
        <img
          src={url}
          alt={fileName}
          className={className ?? ""}
          height={height}
          width={width}
          {...props}
          onError={handleImageError}
        />
      )}
    </>
  );
};
