import { CLIENT_ENVIRONMENT } from "@routes/constants";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import { ENVIRONMENT } from "envs";
import { logEvent } from "firebase/analytics";
import {analytics} from "libs";

let analyticsSegment: Analytics | undefined;

export const initAnalytics = async () => {
  if (!analyticsSegment) {
    const [loadedAnalytics] = await AnalyticsBrowser.load({
      writeKey: "aNU3cRlE2o4yHqTrQuqF1CEwHUz8lvj8",
    });
    analyticsSegment = loadedAnalytics;
  }
  return analyticsSegment;
};

export const trackPageSG = (pageName: string, properties?: Record<string, any>, email?:string) => {
   if (
    ENVIRONMENT === CLIENT_ENVIRONMENT.prod &&
    (!email || !email?.includes("satschel.com"))
  ) {
    analyticsSegment?.page(pageName);
  }
};

export const trackEventSG = (
  eventName: string,
  properties?: Record<string, any>,
  email?:string
) => {
     if (
    ENVIRONMENT === CLIENT_ENVIRONMENT.prod &&
    (!email || !email?.includes("satschel.com"))
  ) {
  analyticsSegment?.track(eventName, properties);
  logEvent(analytics, eventName, properties );
  }
};

export default analyticsSegment;
