import { getToken, onMessage } from "firebase/messaging";
import { useCallback, useEffect, useRef } from "react";
import { APIS } from "../../constant";
import { useCookie } from "@hooks/cookie";
import { useNetwork } from "@hooks/network";
import { messaging } from "libs";
import { useNotification } from "@hooks/notification";

export const useFirebaseNotification = () => {
  const { post: sendDevice } = useNetwork();
  const { get: getCookieData } = useCookie();
  const userDetails = getCookieData("userDetails");
  const { token: loginToken } = userDetails ?? {};
  const callOnlyFirstTime = useRef(false);
  const { infoNotification } = useNotification()

  useEffect(() => {
    if (loginToken && !callOnlyFirstTime.current) {
      requestPermission();
      callOnlyFirstTime.current = true;
    }
  }, [loginToken, callOnlyFirstTime.current]);

  const onMessageListener = useCallback(() => {
    onMessage(messaging, (payload) => {
      const notificationTitle = payload?.data?.title as string;
      const notificationOptions = {
        body: payload?.data?.body,
        icon: payload?.data?.image,
      };
      const greeting = new Notification(notificationTitle, notificationOptions);
      greeting.onclick = () => window.open(payload?.data?.click_action);
    });
  }, [messaging]);

  const requestPermission = useCallback(async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Generate Token
        const token = await getToken(messaging, {
          vapidKey:
            "BOeFqKVF6UBuRTtz2sRqXcZUpopg62nHl4hGQac3ar5ZW0N0s9yPrsDWlCYQCu_yjhGM3Ka9wT3Fo2LBTqrwm_Q",
        });
        const res = await sendDevice(APIS.DeviceTokens, { token, type: "web" });
        // Send this token  to server ( db)
      } else if (permission === "denied") {
        if (localStorage.getItem('notified') === 'yes') {
          return
        }
        infoNotification("Please enable your Notifications");
        localStorage.setItem('notified', 'yes')
      }
    } catch (e) {
      console.error(e);
    }
  }, [messaging]);

  return { messaging, onMessageListener };
};
