import React, { useEffect } from "react";

import { Json } from "../../types";

interface IVariablePieChart {
  chartData: Json[],
  colors: string[][],
  name: string
}

export const VariablePieChart = ({ name, chartData, colors = [] }: IVariablePieChart) => {

  useEffect(() => {
    const highCharts = (window as any).Highcharts;
    if (!highCharts) {
      console.error('Highcharts is not loaded yet.');
      return;
    }
    highCharts.setOptions({
      colors: colors.map(function (color) {
        return {
          radialGradient: {
            cx: 0.5,
            cy: 0.5,
            r: 0.5
          },
          stops: [
            [0, highCharts.color(color?.[0] || "").brighten(-0.1).get('rgb')],
            [1, color?.[1] || ""]
          ]
        };
      })
    });

    let chart = highCharts.chart('variable-pie-chart', {
      chart: {
        type: 'variablepie',
        backgroundColor: "transparent",
        zooming: {
          mouseWheel: {
            enabled: false,
          }
        },
      },
      title: { text: '' },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        variablepie: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          }
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormat:  `<span class="pie-template">
        <span style="color:{point.color}">\u25CF</span> <b class="value"> {point.name}</b><br/>
        Current: <b class="value">{point.current}</b><br/>
        P&L: <b class="value">{point.gainLoss}</b>
        <span style="color: {point.gainLossPercentageColor}">({point.gainLossPercentage}%)</span>
        </span>`
    },
      series: [{
        minPointSize: 10,
        innerSize: '20%',
        zMin: 0,
        name: name || "",
        borderRadius: 0,
        data: chartData,
      }]
    });
  }, [chartData, colors, name]);

  return (
    <div className="variable-pie-chart-container" id="variable-pie-chart" />
  );
};
