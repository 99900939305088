import { Button } from "components/button";
import { CountryCode } from "components/country-code";
import { ChangeEvent, FC, KeyboardEvent } from "react";

import "./input.scss";

interface IInput {
  label: string;
  suffixLabelIcon?: any;
  inputType:
    | "text"
    | "number"
    | "password"
    | "date"
    | "datetime-local"
    | "email"
    | "time";
  placeholder: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  isCountryCodeVisible?: boolean;
  handleChangeCountry?: (e: any) => void;
  isShowButton?: boolean;
  handleClickBtn?: () => void;
  errorMessage?: string;
  isError?: boolean;
  handleClearField?: () => void;
  handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  allowClear?: boolean;
  id?: string;
  handleBlur?: () => void;
  prefixIcon?: string;
  disabled?: boolean;
  suffixIcon?: string;
  handleSuffixIcon?: () => void;
  autoComplete?: string;
  countryCode?: string;
  inputName?: string;
  maxLength?: number;
  required?: boolean;
  autoFocus?: boolean;
  prefixText?: string;
  prefixTextClassName?: string;
  suffixText?: string;
  prefixValue?: string;
  prefixValueClassName?: string;
  max?: string;
  min?: string | number;
  readonly?: boolean;
  className?: string;
  pattern?: any;
  inputDesc?: any;
  inputPrefixDesc?: any;
  buttonLabel?: any;
  buttonDisabled?: boolean;
  step?: string;
  title?: string;
}

export const Input: FC<IInput> = ({
  label,
  suffixLabelIcon,
  inputType,
  id,
  placeholder,
  handleChange,
  value,
  handleKeyPress,
  allowClear = false,
  handleClearField,
  isError = false,
  errorMessage,
  isShowButton,
  handleClickBtn,
  isCountryCodeVisible,
  handleChangeCountry,
  handleBlur = () => {},
  prefixIcon,
  disabled = false,
  suffixIcon,
  handleSuffixIcon,
  countryCode,
  autoComplete,
  inputName,
  maxLength,
  required = false,
  suffixText,
  prefixText,
  prefixValue,
  autoFocus = false,
  prefixTextClassName,
  prefixValueClassName,
  max,
  min,
  readonly = false,
  className = "",
  pattern,
  inputDesc = "",
  inputPrefixDesc = "",
  buttonLabel = "Add",
  buttonDisabled = false,
  step = "",
  title = ""
}) => {
  return (
    <div className={`input ${className} `}>
      {label && (
        <label htmlFor={id} className="input__label">
          {label} {required && "(required)"} {suffixLabelIcon}
        </label>
      )}
      <div
        className={`input__value-container ${
          readonly === true ? "read-only" : ""
        }`}
      >
        {!!inputPrefixDesc && <div className="input_desc">{inputPrefixDesc}</div>}
        <div className="input__group">
          {prefixValue && (
            <span className={`${prefixValueClassName}`}>{prefixValue}</span>
          )}
          <div
            className={`input__text-fields input__text-fields${
              isError ? "--error" : ""
            }`}
          >
            {isCountryCodeVisible && (
              <CountryCode
                handleChangeCountry={handleChangeCountry as any}
                countryCode={countryCode}
              />
            )}
            {prefixText && <i className={`input__text`}>{prefixText}</i>}
            {prefixIcon && <i className={prefixIcon} />}
            <input
              required={required}
              placeholder={placeholder}
              type={inputType}
              name={inputName}
              onBlur={handleBlur}
              id={id}
              autoFocus={autoFocus}
              className="input__fields"
              onChange={handleChange}
              value={value}
              onKeyPress={handleKeyPress}
              disabled={disabled}
              autoComplete={autoComplete}
              maxLength={maxLength}
              max={max}
              min={min}
              pattern={pattern}
              step={step}
              title={title}
            />
            {allowClear && !!value && (
              <i
                className="ri-close-line input__delete"
                onClick={handleClearField}
              />
            )}
            {suffixText && <i className={`input__text`}>{suffixText}</i>}
            {suffixIcon && (
              <i
                className={`${suffixIcon} input__view`}
                onClick={handleSuffixIcon}
              />
            )}
          </div>
          {isShowButton && (
            <Button
              disabled={buttonDisabled}
              label={buttonLabel}
              handleClick={handleClickBtn}
              type="button__outline button__large input__button"
            />
          )}
        </div>
        {!!inputDesc && <div className="input_desc">{inputDesc}</div>}
        {isError && errorMessage && (
          <span className="input__error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
