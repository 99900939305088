import { useCallback } from "react";
import { APIS } from "../../constant";
import { useCookie } from "@hooks/cookie";
import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { useLocation, useSearchParams } from "react-router-dom";

export const useVerificationsHook = () => {
  const { get: getCookieData } = useCookie();
  const userData = getCookieData("userDetails");
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { put: putSwitchAccountEmailVerify } = useNetwork();
  const { errorNotification, successNotification } = useNotification();

  const handleVerifySwitchAccountEmail = useCallback(async () => {
    const code = searchParams.get("token");
    if (!location.pathname?.includes("/workspace-invite/accept?") && !code) {
      return
    }
    try {
      const res = await putSwitchAccountEmailVerify(`${APIS.WORK_SPACE}/${code}`, {}, { apiResponse: true });
      if (res?.message === "ok" && !!userData) {
        successNotification("Your email has been successfully verified! The new account is now linked and ready to use.")
      } else {
        !!userData && errorNotification(res?.message || "Something went wrong!");
      }
    } catch (e: any) {
      !!userData && errorNotification(e?.message || "Something went wrong!");
    }
  }, [])


  return { handleVerifySwitchAccountEmail };
};
